import { useRouter } from "next/router";
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";

/**
 * @param children
 * @returns {JSX.Element}
 */

export default function Layout({ children }) {
  const router = useRouter();
  const hideHeaderFooter = router.pathname === "/why-register-with-us";

  return (
    <>
      {!hideHeaderFooter && <SiteHeader />}
      <main>{children}</main>
      {!hideHeaderFooter && <SiteFooter />}
    </>
  );
}


